import { AuthService } from '@app/data-access/services/system/auth/auth.service';
import type { VisibleModificationInfoFragment } from '@oms/generated/frontend';
import { ValstroEntitlements } from '@oms/generated/frontend';
import type { ActionComponentConfig, ActionContext, ActionDefFactory } from '@oms/frontend-vgrid';
import { openMessageDialog } from '@app/common/dialog/dialog.common';
import { PendingModificationsService } from '../pending-modifications.datasource.service';
import { doesRowHaveAcceptingStatus } from './inline.pending-modification.action';

type AcceptPendingModificationActionArgs = { id?: string | null | undefined };

export const acceptPendingModificationOnChange = async <T extends AcceptPendingModificationActionArgs>(
  // TODO: add "OmsNotification" once Notification migration is ready
  ctx: ActionContext<T, ActionComponentConfig<T>>
) => {
  const { lifecycle, data } = ctx;
  const selectedRow = data[0];

  if (lifecycle === 'change') {
    if (!selectedRow) {
      return;
    }
    const id = selectedRow.id;
    if (!id) {
      return;
    }

    const pendingModificationService = ctx.appContainer.resolve(PendingModificationsService);

    try {
      ctx.notify({ isLoading: true, rowData: selectedRow });
      // TODO: add 'selectedRow.resourceId' once Notification migration is ready
      // For Notifications grid we use 'resourceId' property as identifier for action.
      // For IO modify widget we use 'id' property
      const resp = await pendingModificationService.acceptModification(id);
      if (resp.isSuccess()) {
        ctx.notify({ isLoading: false, rowData: selectedRow });
      } else {
        ctx.notify({ isLoading: false, rowData: selectedRow });
        const msgs = resp?.errors.map((e) => e.message).join(', ');
        openMessageDialog(`Error: ${msgs}`, ctx.workspace).catch(console.error);
        throw new Error(msgs);
      }
    } catch (e) {
      ctx.notify({ isLoading: false, rowData: selectedRow });
      openMessageDialog(`Error: ${String(e)}`, ctx.workspace).catch(console.error);
      console.error(e);
    }
  }
};

export const acceptPendingModificationAction: ActionDefFactory<VisibleModificationInfoFragment> = (builder) =>
  builder
    .name('accept_pending_modification')
    .access(({ appContainer }) => {
      const authService = appContainer.resolve(AuthService);
      return authService.hasEntitlement([ValstroEntitlements.OrderManage]);
    })
    .customMenu((m) => m.name('Accept').visible(({ rowData: [row] }) => !doesRowHaveAcceptingStatus(row)))
    .onChange<ActionComponentConfig<VisibleModificationInfoFragment>>(acceptPendingModificationOnChange);
