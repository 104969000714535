import { useMemo } from 'react';
import { parseISO, format } from 'date-fns';
import {
  Box,
  DISPLAY_FIELD_COMPONENT_TYPE,
  DisplayGrid,
  DISPLAY_GRID_CUSTOM_COMPONENT_TYPE
} from '@oms/shared-frontend/ui-design-system';
import type { DisplayGridProps } from '@oms/shared-frontend/ui-design-system';
import { OrderSide } from '@oms/generated/frontend';
import type { InvestorOrderRow } from '@oms/generated/frontend';
import { format as priceFormat } from '@oms/shared-frontend/ui-design-system';
import { useInstrumentVwap } from '@app/data-access/services/marketdata/hooks/use-instrument-vwap.hook';
import {
  destinationColorsMap,
  DirectionType
} from '@app/data-access/services/marketdata/components/market-data.display.util';
import {
  useGetOrderVwapFromFactset,
  VolumeWeightedAveragePrice
} from '../order-vwap/hooks/use-order-vwap.hook';
type GridPopoverExecutionsProps = {
  //TODO: implement dailyExecutions when available
  dailyExecutions?: any; //InvestorOrderRow['dailyExecutions'];
  totalExecutedQty?: InvestorOrderRow['executedQuantity'];
  totalAveragePrice?: InvestorOrderRow['averagePrice'];
};

const gridProps: DisplayGridProps['gridProps'] = {
  columns: 3,
  rows: 4,
  columnGap: 10,
  rowGap: 3
};

const gridPopoverExecutionsProps: DisplayGridProps['gridProps'] = {
  columns: 1,
  rows: 1,
  columnGap: 0,
  rowGap: 2
};

const formatMonthAndDay = (dateString: string): string => {
  const date = parseISO(dateString);
  const formattedDate = format(date, 'MMM dd');
  return formattedDate;
};

const getDirection = (side: string, avgPrice: number, vwap: VolumeWeightedAveragePrice): DirectionType => {
  if (!vwap || !avgPrice) return 'neutral';
  if (vwap === avgPrice) return 'neutral';

  if (side === OrderSide.Buy) {
    return vwap > avgPrice ? 'up' : 'down';
  }

  if (side === OrderSide.Sell) {
    return vwap < avgPrice ? 'up' : 'down';
  }

  return 'neutral';
};

const getColor = (direction: DirectionType) => destinationColorsMap[direction];

const pricePerformanceOrDefault = (avgPrice: number, vwap: VolumeWeightedAveragePrice, side?: string) => {
  if (!vwap) return '-';
  if (side === OrderSide.Buy) {
    const performance = vwap - avgPrice;
    if (performance === vwap) return '-';
    return priceFormat('price-change', performance, { decimalScale: 2 });
  } else if (side === OrderSide.Sell) {
    const performance = avgPrice - vwap;
    if (performance === vwap) return '-';
    return priceFormat('price-change', performance, { decimalScale: 2 });
  } else {
    return priceFormat('price', vwap, { decimalScale: 2 });
  }
};

const gridPopoverDailyExecutions = ({
  dailyExecutions,
  totalExecutedQty,
  totalAveragePrice
}: GridPopoverExecutionsProps): DisplayGridProps['items'] => {
  if (!dailyExecutions?.length) {
    return [];
  }

  const dailyExecutionRows: DisplayGridProps['items'] = dailyExecutions?.map((execution: any) => {
    const { averagePrice, executedQuantity, executionDate } = execution;

    const today = new Date().toISOString().split('T')[0];

    return {
      component: {
        label: executionDate === today ? 'Today' : `${formatMonthAndDay(executionDate)}`,
        type: DISPLAY_GRID_CUSTOM_COMPONENT_TYPE.DailyStats,
        items: [
          {
            value: executedQuantity,
            format: 'quantity'
          },
          {
            value: averagePrice,
            format: 'decimal'
          }
        ]
      }
    };
  });

  const totalRowStyle = {
    borderTopStyle: 'solid',
    borderTopWidth: '1px',
    borderColor: 'text.semiMinor',
    paddingTop: '3',
    paddingInline: '3',
    marginLeft: '-3',
    marginRight: '-3'
  } as Record<string, string>;

  return [
    ...dailyExecutionRows,
    {
      component: {
        label: 'Total',
        type: DISPLAY_GRID_CUSTOM_COMPONENT_TYPE.DailyStats,
        sx: totalRowStyle,
        items: [
          {
            value: totalExecutedQty || 0,
            format: 'quantity'
          },
          {
            value: totalAveragePrice || 0,
            format: 'decimal'
          }
        ]
      }
    }
  ];
};

const getDisplayGridItems = (
  values: InvestorOrderRow,
  instrumentVwap: number,
  isToday: boolean,
  orderVwap: VolumeWeightedAveragePrice
): DisplayGridProps['items'] => {
  const items = gridPopoverDailyExecutions({
    //TODO: implement dailyExecutions when available
    dailyExecutions: /*values.dailyExecutions*/ [],
    totalExecutedQty: values.executedQuantity || 0,
    totalAveragePrice: values.averagePrice || 0
  });
  const { side, averagePrice } = values;
  const instrumentVwapDirection = getDirection(side, averagePrice, instrumentVwap);
  const orderVwapDirection = getDirection(side, averagePrice, orderVwap);

  return [
    {
      component: {
        type: DISPLAY_FIELD_COMPONENT_TYPE.InWindowPopover,
        popoverContent: {
          gridProps: gridPopoverExecutionsProps,
          items,
          style: { minWidth: '21rem' },
          hasClose: false,
          testId: 'io-view-daily-executions-summary-popover'
        },
        value: {
          component: {
            type: DISPLAY_GRID_CUSTOM_COMPONENT_TYPE.Stats,
            items: [
              {
                label: isToday ? 'Filled today' : 'Filled total',
                value: isToday ? values.todayExecutedQuantity || 0 : values.executedQuantity || 0,
                format: 'quantity'
              },
              {
                label: isToday ? 'Avg price today' : 'Avg price total',
                sublabel: values.tradeCurrency ? values.tradeCurrency : '',
                value: isToday ? values.todayAveragePrice || 0 : values.averagePrice || 0,
                format: 'decimal'
              }
            ],
            isTextActive: !!items.length,
            width: '100%'
          },
          testId: ''
        }
      },
      rowSpan: 4
    },
    {
      label: 'Order VWAP',
      component: {
        type: DISPLAY_FIELD_COMPONENT_TYPE.Text,
        value: [
          {
            text: pricePerformanceOrDefault(averagePrice, orderVwap),
            textProps: {
              sx: {
                color: getColor(orderVwapDirection),
                marginRight: 'small'
              }
            }
          },
          {
            text: pricePerformanceOrDefault(averagePrice, orderVwap, side),
            textProps: {
              sx: {
                color: getColor(orderVwapDirection)
              }
            }
          }
        ]
      }
    },
    {
      label: 'Working/Open',
      component: {
        type: DISPLAY_FIELD_COMPONENT_TYPE.Numeric,
        value: [values.workingQuantity || 0, values.openQuantity || 0],
        format: 'quantity',
        display: 'fraction'
      }
    },
    {
      label: 'Instrument VWAP',
      component: {
        type: DISPLAY_FIELD_COMPONENT_TYPE.Text,
        value:
          isToday && instrumentVwap
            ? [
                {
                  text: pricePerformanceOrDefault(averagePrice, instrumentVwap),
                  textProps: {
                    sx: {
                      color: getColor(instrumentVwapDirection),
                      marginRight: 'small'
                    }
                  }
                },
                {
                  text: pricePerformanceOrDefault(averagePrice, instrumentVwap, side),
                  textProps: {
                    sx: {
                      color: getColor(instrumentVwapDirection)
                    }
                  }
                }
              ]
            : '-'
      }
    },
    {
      label: 'Leaves Quantity',
      component: {
        type: DISPLAY_FIELD_COMPONENT_TYPE.Numeric,
        value: values.leavesQuantity || 0,
        format: 'quantity'
      }
    }
  ];
};

export const InvestorOrderSummary = ({
  investorOrder,
  isToday
}: {
  investorOrder: InvestorOrderRow;
  isToday: boolean;
}) => {
  const { instrumentVwap } = useInstrumentVwap({
    ticker: investorOrder.instrumentDisplayCode,
    investorOrderId: investorOrder.id,
    isToday
  });

  const { vwap: orderVwap } = useGetOrderVwapFromFactset({
    investorOrderId: investorOrder.id,
    tickerSymbol: investorOrder.instrumentDisplayCode,
    startTime: investorOrder.createdTimestamp
  });

  const displayGridItems = useMemo(() => {
    return getDisplayGridItems(investorOrder, instrumentVwap, isToday, orderVwap);
  }, [investorOrder, isToday, instrumentVwap, orderVwap]);

  return (
    <Box sx={{ padding: 5 }}>
      <DisplayGrid items={displayGridItems} gridProps={gridProps} labelSize="small" />
    </Box>
  );
};
