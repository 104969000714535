import 'reflect-metadata';
import type { ColDef, ColGroupDef, GetDataPath, ICellRendererParams } from '@ag-grid-community/core';
import { ColumnApi, GridApi } from '@ag-grid-community/core';
import { isFunction } from 'lodash';
import { EventSource, type GridEventType } from '../event-handlers/event.source';
import type { EventHandler } from '../event-handlers/event.handler';
import { DEFAULT_CELL_RENDERERS } from '../renderers/cell-renderers/default-renderers';
import { ColumnBuilder } from './column.builder';
import { DatasourceBuilder } from './datasource.builder';
import { container, type DependencyContainer, type InjectionToken } from 'tsyringe';
import { LoadingOverlay } from '../components/loading.overlay.component';
import { defaultGridProps, type VGridProps } from '../components/vgrid.component';
import { RowSelectionBuilder } from './row.selection.builder';
import type { ComponentType } from 'react';
import { SidebarBuilder } from './side-bar.builder';
import { MarketDataBuilder } from './marketdata.builder';
import { ColumnGroupBuilder } from './column.group.builder';
import { MasterDetailBuilder } from './master-detail.builder';
import { DetailGridBuilder } from './detail-grid.builder';
import { type BaseNoRowsOverlayProps, DefaultNoRowsOverlay } from '../components/base.no-rows.overlay';
import { GridMenuOptionsBuilder } from './grid-menu-options.builder';
import {
  type Named,
  isDeinitializable,
  isDescribed,
  isLabeled,
  type BuilderCallback
} from '@oms/shared/util-types';
import { Logger } from '@oms/shared/util';
import { GridActionsBuilder } from './grid.actions.builder';
import type {
  ClientSideTreeConfig,
  ColumnBuilderCallback,
  ColumnLibrary,
  DatasourceTemplate,
  GridBuilderColumnLibrary,
  RowStateRules,
  ServerSideTreeConfig
} from './grid.builder.types';
import type { AnyRecord } from '@oms/frontend-foundation';
import { GridEventHandler } from '../event-handlers/grid.event.handler';
import { DatasourceEventHandler } from '../event-handlers/datasource.event.handler';
import { FilterModelEventHandler } from '../event-handlers/filter-model.event.handler';
import { GridColumnHeadersEventHandler } from '../event-handlers/grid-column-headers.event.handler';
import { ActionsEventHandler } from '../event-handlers/actions.event.handler';
import { RowSelectionEventHandler } from '../event-handlers/row-selection.event.handler';
import { isClientSideTreeConfig } from './grid.builder.util';
import merge from 'lodash/merge';
import { ActionMessage, ActionsConfig } from '../models/actions.model';
import { Datasource } from '../models/datasource.model';
import { RowStatusClassEnum } from '../models/row-states.model';
import { RowSelection } from '../models/row.selection.model';
import {
  OnToolbarHotkeyEvent,
  ToolbarStrategies,
  ToolbarStrategyConfig,
  ToolbarLocationRecord,
  ToolbarLocationKeys,
  ToolbarStrategy
} from '../models/toolbar.model';
import { VGridInstance } from '../models/v.instance.model';
import { FilterModel } from '../models/filter.model';
import { AgGridReactProps } from '@ag-grid-community/react';
import { ColumnBuilderField } from './column.builder.types';
import { Subject } from 'rxjs';

const l = Logger.named('GridBuilder');

const defaultGridSettings = {
  suppressCellFocus: true,
  loadingOverlayComponent: LoadingOverlay,
  noRowsOverlayComponent: DefaultNoRowsOverlay,
  suppressAggFuncInHeader: true
};

function getDefaultGridSettings<TData extends AnyRecord>(): VGridProps<TData> {
  return { ...defaultGridSettings } as VGridProps<TData>;
}

export type GridBuilderCallback<TData extends AnyRecord> = (
  builder: GridBuilder<TData>
) => GridBuilder<TData>;

export class GridBuilder<TData extends AnyRecord> {
  private gridSettings: VGridProps<TData>;
  private eventSource!: EventSource<GridEventType>;
  private eventHandlerTokens: InjectionToken<EventHandler>[] = [
    GridEventHandler,
    DatasourceEventHandler,
    FilterModelEventHandler,
    GridColumnHeadersEventHandler,
    ActionsEventHandler
  ];
  private eventHandlers: EventHandler[] = [];
  private serviceHandlerTokens: InjectionToken[] = [];
  private _datasource!: Datasource<TData>;
  private _rowSelectionBuilder?: RowSelectionBuilder<TData>;
  private _rowSelection?: RowSelection<TData>;
  private _gridActionsConfig?: ActionsConfig<TData>;
  private _toolbarStrategies: ToolbarStrategyConfig = 'has-components';
  private _actions$ = new Subject<ActionMessage<TData>>();

  constructor(gridSettings: VGridProps<TData> = getDefaultGridSettings<TData>()) {
    this.gridSettings = { ...getDefaultGridSettings<TData>(), ...gridSettings } as VGridProps<TData>;
    this.gridSettings.gridMenuOptions = new GridMenuOptionsBuilder<TData>().standardOptions().build();
    this.eventSource = new EventSource(this.gridContainer);
    this.loadComponentRegistry();
    this.registerCoreServices();
  }

  get gridContainer(): DependencyContainer {
    this.gridSettings.container = this.gridSettings.container || container.createChildContainer();
    return this.gridSettings.container;
  }

  private registerCoreServices(): void {
    this.gridContainer.register<VGridProps<TData>>(VGridInstance.InitialSettings, {
      useValue: this.gridSettings
    });
  }

  private loadComponentRegistry(): void {
    this.gridSettings.components = {
      ...DEFAULT_CELL_RENDERERS
    };
  }

  public registerAppCellRenderers<T extends string>(
    appCellRenderers: Record<T, ComponentType<ICellRendererParams>>
  ): GridBuilder<TData> {
    this.gridSettings.components = {
      ...this.gridSettings.components,
      ...appCellRenderers
    };

    return this;
  }

  private createColDef(arg: ColumnBuilderCallback<TData>, builder?: ColumnBuilder<TData>): ColDef<TData> {
    const builderResult = isFunction(arg)
      ? arg(builder || new ColumnBuilder<TData>())
      : ColumnBuilder.of<TData>({
          ...(builder ? builder.build() : {})
        } as ColDef<TData, any>);

    return builderResult.build();
  }

  public loadingOverlay<LoadingParams extends object = {}>(
    loadingOverlay: ComponentType<LoadingParams>,
    params: LoadingParams = {} as LoadingParams
  ): GridBuilder<TData> {
    this.gridSettings.loadingOverlayComponent = loadingOverlay;
    this.gridSettings.loadingOverlayComponentParams = params;
    return this;
  }

  /**
   * Allows user to provide callback for when a toolbar is ctrl+click'ed.
   * @param cb Fires callback when ctrl+click happens on a toolbar.
   * @returns {GridBuilder<TData>}
   */
  public onToolbarHotkey(cb: (e: OnToolbarHotkeyEvent<TData>) => void | Promise<void>): GridBuilder<TData> {
    this.gridSettings.onToolbarHotkey = cb;
    return this;
  }

  public noRowsOverlayComponent<NoRowsParams extends BaseNoRowsOverlayProps = BaseNoRowsOverlayProps>(
    noRowsOverlay: ComponentType<NoRowsParams>,
    params?: NoRowsParams
  ): GridBuilder<TData> {
    this.gridSettings.noRowsOverlayComponent = noRowsOverlay;
    if (params) this.gridSettings.noRowsOverlayComponentParams = params;
    return this;
  }

  public context(context: Record<string, any>): GridBuilder<TData> {
    const existingContext = this.gridSettings.context || {};
    this.gridSettings.context = { ...existingContext, ...context };
    return this;
  }

  public sideBar(cb?: (builder: SidebarBuilder<TData>) => SidebarBuilder<TData>): GridBuilder<TData> {
    const builder = new SidebarBuilder<TData>();
    this.gridSettings.sideBar = (cb ? cb(builder) : builder.useDefaults()).build();
    return this;
  }

  public headerHeight(height: number): GridBuilder<TData> {
    this.gridSettings.headerHeight = height;
    return this;
  }

  public rowHeight(height: number): GridBuilder<TData> {
    this.gridSettings.rowHeight = height;
    return this;
  }

  public rowSelection(
    cb: (builder: RowSelectionBuilder<TData>) => RowSelectionBuilder<TData>
  ): GridBuilder<TData> {
    if (!this._rowSelectionBuilder) {
      this._rowSelectionBuilder = cb(new RowSelectionBuilder<TData>(this.gridSettings));
    }

    this._rowSelection = this._rowSelectionBuilder.build();
    if (this._rowSelection.broadcast) {
      this.eventHandlerTokens.push(RowSelectionEventHandler);
    }
    return this;
  }

  public reactiveCustomComponents(): GridBuilder<TData> {
    this.gridSettings.reactiveCustomComponents = true;
    return this;
  }

  public defaultColumn(
    arg: ColumnBuilderCallback<TData>,
    builder?: ColumnBuilder<TData>
  ): GridBuilder<TData> {
    this.gridSettings.defaultColDef = this.gridSettings.defaultColDef || {};
    const columnDef = this.createColDef(arg, builder);
    this.gridSettings.defaultColDef = { ...columnDef } as ColDef<TData, any>;

    return this;
  }

  public columnDefs(colDefs: (ColDef<TData> | ColGroupDef<TData>)[]): GridBuilder<TData> {
    this.gridSettings.columnDefs = colDefs;
    return this;
  }

  public autoGroupColumnDef(columnDef: ColDef<TData>): GridBuilder<TData> {
    this.gridSettings.autoGroupColumnDef = columnDef;
    return this;
  }

  public supressAutoSize(suppressAutoSize = true): GridBuilder<TData> {
    this.gridSettings.suppressAutoSize = suppressAutoSize;
    return this;
  }

  public stopEditingWhenCellsLoseFocus(): GridBuilder<TData> {
    this.gridSettings.stopEditingWhenCellsLoseFocus = true;
    return this;
  }

  public actions(cb: BuilderCallback<GridActionsBuilder<TData>>): GridBuilder<TData> {
    const newActions = cb(new GridActionsBuilder<TData>(this._actions$)).build();

    this._gridActionsConfig = merge(this._gridActionsConfig || {}, newActions);

    this.gridSettings.columnDefs = this.gridSettings.columnDefs || [];
    const columnDefs = this.gridSettings.columnDefs;

    Object.values(this._gridActionsConfig.schema).forEach((actionConfig) => {
      if (actionConfig.inline) {
        columnDefs.push(actionConfig.inline);
      }

      if (actionConfig.customMenu) {
        this.gridSettings.hasCustomContextMenu = true;
        this.gridSettings.suppressContextMenu = false;
      }
    });

    return this;
  }

  /**
   * masterDetail is used to add detail grid which is dependant on the master row to
   * supply the details. It doesn't support a completely standalone grid with actions.
   */
  public masterDetail<TMasterDetail extends AnyRecord = AnyRecord>(
    cb: (builder: MasterDetailBuilder<TData, TMasterDetail>) => MasterDetailBuilder<TData, TMasterDetail>
  ): GridBuilder<TData> {
    const result = cb(new MasterDetailBuilder<TData, TMasterDetail>()).build();
    this.mergeMasterDetailsIntoGridSettings(result);
    return this;
  }

  /**
   * detailGrid is used to add custom detail grid which is a completely standalone grid,
   * with its own data source and supporting inline actions and toolbar.
   * A header component can also be added.
   */
  public detailGrid<THeader extends AnyRecord, TDetail extends AnyRecord = AnyRecord>(
    cb: (builder: DetailGridBuilder<TData, THeader, TDetail>) => DetailGridBuilder<TData, THeader, TDetail>
  ): GridBuilder<TData> {
    const result = cb(new DetailGridBuilder<TData, THeader, TDetail>()).build();
    this.mergeMasterDetailsIntoGridSettings(result);
    return this;
  }

  private mergeMasterDetailsIntoGridSettings(result: {
    masterDetailGridSettings: AgGridReactProps<TData>;
    groupByColumnId: ColumnBuilderField<TData>;
    groupByColDefOverrides: ColDef<TData, any>;
  }) {
    this.gridSettings = { ...this.gridSettings, ...result.masterDetailGridSettings } as VGridProps<TData>;

    const currentColumns: (ColDef<TData> | ColGroupDef<TData>)[] =
      this.gridSettings.columnDefs || ([] as (ColDef<TData> | ColGroupDef<TData>)[]);

    // TODO: Breaks ESLint Sever
    const gridSettingsColumnDefs = currentColumns.map((col) => {
      if (
        (col as ColDef<TData>).colId === result.groupByColumnId ||
        (col as ColDef<TData>).field === result.groupByColumnId
      ) {
        return {
          ...col,
          ...result.groupByColDefOverrides
        };
      } else {
        return col;
      }
    });

    // TODO: Breaks ESLint Sever
    this.gridSettings.columnDefs = gridSettingsColumnDefs;
  }

  public menuOptions(
    cb: (builder: GridMenuOptionsBuilder<TData>) => GridMenuOptionsBuilder<TData>
  ): GridBuilder<TData> {
    const result = cb(new GridMenuOptionsBuilder<TData>()).build();
    this.gridSettings.gridMenuOptions = result;
    return this;
  }

  public disableMenuOptions(): GridBuilder<TData> {
    this.gridSettings.gridMenuOptions = [];
    return this;
  }

  public marketData(
    cb?: (builder: MarketDataBuilder<TData>) => MarketDataBuilder<TData>
  ): GridBuilder<TData> {
    if (!cb) {
      return this;
    }

    const result = cb(new MarketDataBuilder<TData>()).build();

    this.gridSettings.columnDefs = this.gridSettings.columnDefs || [];

    if (result.level1) {
      this.gridSettings.columnDefs.push(...result.level1);
    }

    return this;
  }

  public colGroup(cb: (builder: ColumnGroupBuilder<TData>) => ColumnGroupBuilder<TData>): GridBuilder<TData> {
    this.gridSettings.columnDefs = this.gridSettings.columnDefs || [];
    this.gridSettings.columnDefs.push(cb(new ColumnGroupBuilder<TData>()).build());

    return this;
  }

  public column(arg: ColumnBuilderCallback<TData>, builder?: ColumnBuilder<TData>): GridBuilder<TData> {
    this.gridSettings.columnDefs = this.gridSettings.columnDefs || [];

    const columnDef = this.createColDef(arg, builder);
    this.gridSettings.columnDefs.push(columnDef);

    return this;
  }

  public columns(...builders: ColumnBuilderCallback<TData>[]): GridBuilder<TData> {
    builders.forEach((b) => {
      this.column(b);
    });
    return this;
  }

  /**
   * @param columnLibrary - A column library to apply to the builder
   * @returns A reference to `this` builder
   */
  public columnLibrary(columnLibrary: ColumnLibrary<TData>): GridBuilder<TData> {
    const { defaultColumn, columns } = columnLibrary;
    if (defaultColumn) {
      this.defaultColumn(defaultColumn);
    }
    this.columns(...columns);
    return this;
  }

  public tableServerColumnLibrary(columnLibrary: GridBuilderColumnLibrary<TData>): GridBuilder<TData> {
    const { defaultColumn, columns } = columnLibrary;
    if (defaultColumn) {
      this.gridSettings.defaultColDef = defaultColumn;
    }
    this.gridSettings.columnDefs = columns;
    return this;
  }

  public injectEvents<T extends InjectionToken<EventHandler<any>>[]>(tokens: T): GridBuilder<TData> {
    this.eventHandlerTokens = [...this.eventHandlerTokens, ...tokens];
    return this;
  }

  /**
   * @deprecated Use injectEvents instead and inject the service tokens from there
   */
  public injectServices(tokens: InjectionToken[]): GridBuilder<TData> {
    this.serviceHandlerTokens = [...this.serviceHandlerTokens, ...tokens];
    return this;
  }

  /**
   * Run a function when a grid is ready (not populated)
   * @param cb event handler
   */
  public onVGridReady(cb: VGridProps<TData>['onVGridReady']): GridBuilder<TData> {
    const onVGridReady = this.gridSettings.onVGridReady;
    this.gridSettings.onVGridReady = onVGridReady
      ? (e) => {
          onVGridReady(e);
          cb?.(e);
        }
      : cb;
    return this;
  }

  /**
   * Run a function when a grid is first populated
   * @param cb event handler
   */
  public onVGridFirstDataRendered(cb: VGridProps<TData>['onVGridFirstDataRendered']): GridBuilder<TData> {
    this.gridSettings.onVGridFirstDataRendered = cb;
    return this;
  }

  public datasource(datasource: DatasourceTemplate<TData>): GridBuilder<TData> {
    this._datasource = isFunction(datasource)
      ? datasource(new DatasourceBuilder<TData>()).build()
      : this.gridContainer.resolve(datasource);
    this.gridSettings.getRowId = this._datasource.rowId || this.gridSettings.getRowId;
    this.gridSettings.rowModelType = this._datasource.rowModelType;
    this.gridSettings.cacheBlockSize = this._datasource.cacheBlockSize;
    this.gridSettings.maxBlocksInCache = this._datasource.maxBlocksInCache;
    this.gridSettings.serverSideDatasource = this._datasource.serverSideDatasource;
    this.gridSettings.rowData = this._datasource.rowData;

    if (this.gridSettings.rowModelType === 'infinite') {
      this.gridSettings.infiniteInitialRowCount = 100;
      this.gridSettings.cacheOverflowSize = 100;
      this.gridSettings.rowBuffer = 66;
    }

    return this;
  }

  public filters(filters: FilterModel): GridBuilder<TData> {
    this.gridSettings.filters = filters;
    return this;
  }

  /**
   * @deprecated Does nothing in V2
   */
  public syncOfflineState(): GridBuilder<TData> {
    this.gridSettings.syncOfflineState = true;
    return this;
  }

  public rowStateRules({
    // Legacy row states. TODO: Consider removing once they're all no longer in use.
    noAction = () => false,
    takeAction = () => false,
    pendingAction = () => false,
    disabled = () => false,
    error = () => false,
    warn = () => false,
    // Current row states
    pending = () => false,
    hazard = () => false,
    noExecutedQuantity = () => false,
    hasExecutedQuantity = () => false
  }: RowStateRules<TData>): GridBuilder<TData> {
    this.gridSettings.rowClassRules = {
      ...(this.gridSettings.rowClassRules || {}),
      [RowStatusClassEnum.NoAction]: noAction,
      [RowStatusClassEnum.TakeAction]: takeAction,
      [RowStatusClassEnum.PendingAction]: pendingAction,
      [RowStatusClassEnum.Disabled]: disabled,
      [RowStatusClassEnum.Error]: error,
      [RowStatusClassEnum.Warn]: warn,
      [RowStatusClassEnum.Pending]: pending,
      [RowStatusClassEnum.Hazard]: hazard,
      [RowStatusClassEnum.NoExecutedQuantity]: noExecutedQuantity,
      [RowStatusClassEnum.HasExecutedQuantity]: hasExecutedQuantity
    };
    return this;
  }

  /** Make tree config for client-side rendering model */
  public makeTree(clientSideTreeConfig: ClientSideTreeConfig<TData>): GridBuilder<TData>;

  /** Make tree config for client-side rendering model */
  public makeTree(getDataPath: GetDataPath<TData>, autoExpanded?: boolean): GridBuilder<TData>;

  /** Make tree config for server-side rendering model */
  public makeTree(serverSideTreeConfig: ServerSideTreeConfig<TData>): GridBuilder<TData>;

  // Implementation only -- Public overloads above 👆
  public makeTree(
    getDataPathOrConfigObject: ClientSideTreeConfig<TData> | ServerSideTreeConfig<TData> | GetDataPath<TData>,
    autoExpanded?: boolean
  ): GridBuilder<TData> {
    if (typeof getDataPathOrConfigObject === 'function') {
      const getDataPath = getDataPathOrConfigObject;
      return this.makeTree_clientSide({
        getDataPath,
        autoExpanded
      });
    } else if (isClientSideTreeConfig<TData>(getDataPathOrConfigObject)) {
      const clientSideTreeConfig = getDataPathOrConfigObject;
      return this.makeTree_clientSide(clientSideTreeConfig);
    } else {
      const serverSideTreeConfig = getDataPathOrConfigObject;
      return this.makeTree_serverSide(serverSideTreeConfig);
    }
  }

  private makeTree_clientSide(clientSideTreeConfig: ClientSideTreeConfig<TData>): GridBuilder<TData> {
    const { getDataPath, autoExpanded } = clientSideTreeConfig;
    this.gridSettings.treeData = true;
    this.gridSettings.getDataPath = getDataPath;
    if (autoExpanded) {
      this.gridSettings.groupDefaultExpanded = -1;
    }
    return this;
  }

  private makeTree_serverSide(serverSideTreeConfig: ServerSideTreeConfig<TData>): GridBuilder<TData> {
    const { getGroupKey, isGroup, isGroupOpenByDefault } = serverSideTreeConfig;
    this.gridSettings.treeData = true;
    this.gridSettings.rowModelType = 'serverSide';
    if (getGroupKey) this.gridSettings.getServerSideGroupKey = getGroupKey;
    if (isGroup) this.gridSettings.isServerSideGroup = isGroup;
    if (isGroupOpenByDefault) this.gridSettings.isServerSideGroupOpenByDefault = isGroupOpenByDefault;
    return this;
  }

  private registerAllValues(): void {
    this.gridContainer.register<Subject<ActionMessage<TData>>>(VGridInstance.GridActionEvents, {
      useValue: this._actions$
    });

    this.gridContainer.register<VGridProps<TData>>(VGridInstance.InitialSettings, {
      useValue: this.gridSettings
    });

    this.gridContainer.register<Datasource<TData>>(VGridInstance.Datasource, { useValue: this._datasource });

    this.gridContainer.register(VGridInstance.RowSelection, {
      useValue: this._rowSelection
    });

    this.gridContainer.register<DependencyContainer>(VGridInstance.Container, {
      useValue: this.gridContainer
    });

    this.gridContainer.register<ActionsConfig<TData>>(VGridInstance.GridActionsConfig, {
      useValue: this._gridActionsConfig || ({} as ActionsConfig<TData>)
    });

    this.gridContainer.register<ToolbarStrategies>(VGridInstance.ToolbarStrategies, {
      useValue: Object.keys(ToolbarLocationRecord).reduce((record, t) => {
        const key = t as ToolbarLocationKeys;
        if (typeof this._toolbarStrategies === 'string') {
          record[key] = this._toolbarStrategies;
        } else if (!this._toolbarStrategies[key]) {
          record[key] = 'has-components';
        } else {
          record[key] = this._toolbarStrategies[key] as ToolbarStrategy;
        }
        return record;
      }, {} as ToolbarStrategies)
    });
  }

  public editType(type: 'fullRow' | undefined): GridBuilder<TData> {
    this.gridSettings.editType = type;
    return this;
  }

  public pinnedTopRowData(data: TData[]): GridBuilder<TData> {
    this.gridSettings.pinnedTopRowData = data;
    return this;
  }

  public toolbarStrategies(strategies: ToolbarStrategyConfig = 'has-components'): GridBuilder<TData> {
    this._toolbarStrategies = strategies;
    return this;
  }

  private checkIfToSuppressVirtualisation(): void {
    const suppressColumnVirtualisation = sessionStorage.getItem('suppressColumnVirtualisation') === 'true';
    const suppressRowVirtualisation = sessionStorage.getItem('suppressRowVirtualisation') === 'true';
    this.gridSettings = {
      ...this.gridSettings,
      suppressColumnVirtualisation:
        this.gridSettings.suppressColumnVirtualisation || suppressColumnVirtualisation,
      suppressRowVirtualisation: this.gridSettings.suppressRowVirtualisation || suppressRowVirtualisation
    };
  }

  public build(): VGridProps<TData> {
    this.checkIfToSuppressVirtualisation();
    this.registerAllValues();

    if (!this._datasource) {
      throw new Error('A datasource is required.');
    }

    this.gridContainer.registerInstance<InjectionToken[]>(
      VGridInstance.InjectedServiceTokens,
      this.serviceHandlerTokens
    );

    const registeredEvents = new Set<string>();

    this.eventHandlers = this.eventHandlerTokens.reduce((result: EventHandler[], curr) => {
      const handler = this.gridContainer.resolve(curr);

      if (registeredEvents.has(handler.name)) {
        l.log(`Already registered events for ${handler.name}. Skipping...`);
        return result;
      }

      handler.addEvents(this.eventSource);
      registeredEvents.add(handler.name);

      result.push(handler);
      return result;
    }, []);

    this.eventSource.forEach((evt, cb) => {
      (this.gridSettings as Record<string, any>)[evt] = (e: any) => cb(e);
    });
    const TypedGridApi = GridApi as InjectionToken<GridApi<TData>>;
    const TypedColumnApi = ColumnApi as InjectionToken<ColumnApi>;
    if (this.gridContainer.isRegistered(TypedGridApi) && this.gridContainer.isRegistered(TypedColumnApi)) {
      // TODO:
      const api = this.gridContainer.resolve(GridApi) as GridApi<AnyRecord>;
      this.eventSource.get('onGridReady')({
        type: 'onGridReady',
        api,
        columnApi: this.gridContainer.resolve(ColumnApi),
        context: this.gridSettings.context
      });
    }

    return this.gridSettings;
  }

  public destroy(): void {
    this.eventHandlers.forEach((c) => c.removeEvents());
    this.serviceHandlerTokens.forEach((token) => {
      this.gridContainer.resolveAll(token).forEach((service) => {
        if (isDeinitializable<any>(service)) {
          const result = service.deinit();
          const template = (r: any, isError?: boolean) => {
            const isResult = typeof r !== 'undefined';
            const tokenName =
              typeof (token as Named).name === 'string' ? (token as Named).name : 'Service token';
            const message = `${tokenName} cleanup${isError ? ' error' : ''}:${
              isResult ? '' : ` ${isError ? 'Unknown failure' : 'Complete'}`
            }`;
            if (!isResult) return [message];
            if (isDescribed(r)) return [message, r.description];
            if (isLabeled(r)) return [message, r.label];
            return [message, r];
          };
          if (result instanceof Promise) {
            result
              .then((r) => {
                l.log(...template(r));
              })
              .catch((e) => {
                l.error(...template(e, true));
              });
          } else {
            l.log(...template(result));
          }
        }
      });
    });
  }

  public static of<TData extends AnyRecord>(
    settings: VGridProps<TData> = defaultGridProps as VGridProps<TData>
  ): GridBuilder<TData> {
    return new GridBuilder<TData>(settings);
  }
}
