import { t } from '@oms/codegen/translations';
import { OrderType } from '@oms/generated/frontend';
import type { NotificationRow, OrderSideType } from '@oms/generated/frontend';
import { SharedNotification, SharedNotificationTargets } from '@oms/shared/oms-common';
import { formatNumber } from '@oms/shared/util';
import { IOHasExecutedQuantity, IONoExecutedQuantity, IOPending } from '../utils/row-state-rule-utils';
import { getParsedPayload } from '../utils/parse-json-utils';
import { orderTypeMapping, sideTypeMapping } from '@app/common/mappers/order-enums';
import { openNewOrders, openPendingModifications, openRepairQueue } from '@app/generated/sdk';
import { RepairQueueTab } from '@app/widgets/trading/repair-queue/schema.repair-queue.layout/repair_queue.snapshots.schema.v-0';
import { AppWorkspace } from '@app/app-config/workspace.config';

const MINUTES_PER_HOUR = 60;
const MINUTES_PER_DAY = 1440;

const someMinsAgo = (minutes: number) => t('app.notifications.grid.elapsedTime.someMinAgo', { minutes });
const someHoursAgo = (hours: number) => t('app.notifications.grid.elapsedTime.someHoursAgo', { hours });
const someDaysAgo = (days: number) => t('app.notifications.grid.elapsedTime.someDaysAgo', { days });

export const getElapsedTime = (createdAt: string) => {
  const pastTime = new Date(createdAt);
  const currentTime = new Date();
  const elapsedMinutes = Math.floor((currentTime.getTime() - pastTime.getTime()) / 60000);
  if (elapsedMinutes < 1) {
    return t('app.notifications.grid.elapsedTime.justNow');
  } else if (elapsedMinutes < MINUTES_PER_HOUR) {
    return elapsedMinutes === 1
      ? t('app.notifications.grid.elapsedTime.oneMinAgo')
      : someMinsAgo(elapsedMinutes);
  } else if (elapsedMinutes > MINUTES_PER_HOUR && elapsedMinutes < MINUTES_PER_DAY) {
    const hoursAgo = Math.floor(elapsedMinutes / MINUTES_PER_HOUR);
    return hoursAgo === 1 ? t('app.notifications.grid.elapsedTime.oneHourAgo') : someHoursAgo(hoursAgo);
  } else {
    const daysAgo = Math.floor(elapsedMinutes / MINUTES_PER_DAY);
    return daysAgo === 1 ? t('app.notifications.grid.elapsedTime.oneDayAgo') : someDaysAgo(daysAgo);
  }
};

export const getNotificationBody = (notification: NotificationRow) => {
  const { name, targetObjectPayload } = notification;

  switch (name as SharedNotification.DomainDefaultNotificationName) {
    case SharedNotification.DomainDefaultNotificationName.IO_NEW:
    case SharedNotification.DomainDefaultNotificationName.IO_FAILED: {
      // The targetObjectPayload is an InvestorOrderCreatePayload
      const payload =
        getParsedPayload<SharedNotificationTargets.InvestorOrderCreatePayload>(targetObjectPayload);
      if (payload) {
        const { investorAccountName, instrumentSymbol, side, quantity, orderType, limitPrice } = payload;
        const priceDisplay =
          orderType === OrderType.Market
            ? orderTypeMapping[OrderType.Market]
            : formatNumber(Number(limitPrice));
        const sideDisplay = side ? sideTypeMapping[side as OrderSideType] : 'Unknown';
        return `${investorAccountName} ${instrumentSymbol} ${sideDisplay} ${formatNumber(Number(quantity))} @ ${priceDisplay}`;
      }
      return `Default body for ${name}`;
    }
    case SharedNotification.DomainDefaultNotificationName.IO_MODIFY:
    case SharedNotification.DomainDefaultNotificationName.IO_CANCEL:
    case SharedNotification.DomainDefaultNotificationName.IO_MODIFY_FAILED: {
      // The targetObjectPayload is an InvestorOrderModifyStylePayload
      const payload =
        getParsedPayload<SharedNotificationTargets.InvestorOrderModifyStylePayload>(targetObjectPayload);
      if (payload) {
        const { investorAccountName, instrumentSymbol, side, quantity, orderType, limitPrice } = payload;
        const priceDisplay =
          orderType === OrderType.Market
            ? orderTypeMapping[OrderType.Market]
            : formatNumber(Number(limitPrice));
        const sideDisplay = side ? sideTypeMapping[side as OrderSideType] : 'Unknown';
        return `${investorAccountName} ${instrumentSymbol} ${sideDisplay} ${formatNumber(Number(quantity))} @ ${priceDisplay}`;
      }
      return `Default body for ${name}`;
    }
    case SharedNotification.DomainDefaultNotificationName.TRADE_FAILED: {
      // The targetObjectPayload is a TradeCreatePayload
      const payload = getParsedPayload<SharedNotificationTargets.TradeCreatePayload>(targetObjectPayload);
      if (payload) {
        const { side, instrumentSymbol, quantity, limitPrice, tradeCounterparty } = payload;
        const priceDisplay = !limitPrice
          ? orderTypeMapping[OrderType.Market]
          : formatNumber(Number(limitPrice));
        const sideDisplay = side ? sideTypeMapping[side as OrderSideType] : 'Unknown';
        return `${sideDisplay} ${instrumentSymbol} ${formatNumber(Number(quantity))} @ ${priceDisplay} ${tradeCounterparty}`;
      }

      return `Default body for ${name}`;
    }
    case SharedNotification.DomainDefaultNotificationName.TRADE_MODIFY_FAILED: {
      // The targetObjectPayload is a TradeModifyStylePayload
      const payload =
        getParsedPayload<SharedNotificationTargets.TradeModifyStylePayload>(targetObjectPayload);
      if (payload) {
        const { side, instrumentSymbol, quantity, limitPrice, tradeCounterparty } = payload;
        const priceDisplay = !limitPrice
          ? orderTypeMapping[OrderType.Market]
          : formatNumber(Number(limitPrice));
        const sideDisplay = side ? sideTypeMapping[side as OrderSideType] : 'Unknown';
        return `${sideDisplay} ${instrumentSymbol} ${formatNumber(Number(quantity))} @ ${priceDisplay} ${tradeCounterparty}`;
      }

      return `Default body for ${name}`;
    }
    // case 'Capital Threshold Warning': {
    //   const { side, instrument, quantity, price, tradeCounterparty } = resourceFields;
    //   return `${side} ${instrument} ${formatNumber(Number(quantity))} @ ${formatNumber(
    //     Number(price)
    //   )} ${tradeCounterparty}`;
    // }
    default:
      return `Default body for ${name}`;
  }
};

// Determines if the given Notification row should have the "Pending" row state.
// export function NotificationPending(rowData: Notification | undefined): boolean {
export function NotificationPending(rowData: NotificationRow | undefined): boolean {
  switch (rowData?.name) {
    case SharedNotification.DomainDefaultNotificationName.IO_NEW: {
      // For IO_NEW, use the targetObjectPayload (should be an InvestorOrderCreatePayload)
      // to determine "Pending" row state.
      return IOPending(
        getParsedPayload<SharedNotificationTargets.InvestorOrderCreatePayload>(rowData?.targetObjectPayload)
      );
    }
    case SharedNotification.DomainDefaultNotificationName.IO_MODIFY:
    case SharedNotification.DomainDefaultNotificationName.IO_CANCEL: {
      // For IO_MODIFY and IO_CANCEL, use the targetObjectPayload (should be an InvestorOrderModifyStylePayload)
      // to determine "Pending" row state.
      return IOPending(
        getParsedPayload<SharedNotificationTargets.InvestorOrderModifyStylePayload>(
          rowData?.targetObjectPayload
        )
      );
    }
    case SharedNotification.DomainDefaultNotificationName.IO_FAILED:
    case SharedNotification.DomainDefaultNotificationName.IO_MODIFY_FAILED:
    case SharedNotification.DomainDefaultNotificationName.TRADE_FAILED:
    case SharedNotification.DomainDefaultNotificationName.TRADE_MODIFY_FAILED: {
      return false;
    }
    // case 'Capital Threshold Warning':
    // case 'Unmatched Trade Report':
    // case 'Trade Report Failed (rejected)':
    // case 'TO Create Rejected':
    // case 'TO Modify Rejected':
    // case 'TO Cancel Rejected':
    // case 'TO Unsolicited Cancelled - Partial Fills':
    // case 'Trade Request (OTC)': {
    //   // TODO: For other notification types, extract the relevant object (TO, Execution, etc.)
    //   // for the "Pending" row state rule.
    //   return false;
    // }
    default:
      return false;
  }
}

// Determines if the given Notification row should have the "NoExecutedQuantity" row state.
export function NotificationNoExecutedQuantity(rowData: NotificationRow | undefined): boolean {
  switch (rowData?.name) {
    case SharedNotification.DomainDefaultNotificationName.IO_NEW: {
      // For IO_NEW, use the targetObjectPayload (should be an InvestorOrderCreatePayload)
      // to determine "NoExecutedQuantity" row state.
      return IONoExecutedQuantity(
        getParsedPayload<SharedNotificationTargets.InvestorOrderCreatePayload>(rowData?.targetObjectPayload)
      );
    }
    case SharedNotification.DomainDefaultNotificationName.IO_MODIFY:
    case SharedNotification.DomainDefaultNotificationName.IO_CANCEL: {
      // For IO_MODIFY and IO_CANCEL, use the targetObjectPayload (should be an InvestorOrderModifyStylePayload)
      // to determine "NoExecutedQuantity" row state.
      return IONoExecutedQuantity(
        getParsedPayload<SharedNotificationTargets.InvestorOrderModifyStylePayload>(
          rowData?.targetObjectPayload
        )
      );
    }
    case SharedNotification.DomainDefaultNotificationName.IO_FAILED:
    case SharedNotification.DomainDefaultNotificationName.IO_MODIFY_FAILED:
    case SharedNotification.DomainDefaultNotificationName.TRADE_FAILED:
    case SharedNotification.DomainDefaultNotificationName.TRADE_MODIFY_FAILED: {
      return false;
    }
    // case 'Capital Threshold Warning':
    // case 'Unmatched Trade Report':
    // case 'Trade Report Failed (rejected)':
    // case 'TO Create Rejected':
    // case 'TO Modify Rejected':
    // case 'TO Cancel Rejected':
    // case 'TO Unsolicited Cancelled - Partial Fills':
    // case 'Trade Request (OTC)': {
    //   // TODO: For other notification types, extract the relevant object (TO, Execution, etc.)
    //   // for the "NoExecutedQuantity" row state rule.
    //   return false;
    // }
    default:
      return false;
  }
}

// Determines if the given Notification row should have the "HasExecutedQuantity" row state.
export function NotificationHasExecutedQuantity(rowData: NotificationRow | undefined): boolean {
  switch (rowData?.name) {
    case SharedNotification.DomainDefaultNotificationName.IO_NEW: {
      // For IO_NEW, use the targetObjectPayload (should be an InvestorOrderCreatePayload)
      // to determine "HasExecutedQuantity" row state.
      return IOHasExecutedQuantity(
        getParsedPayload<SharedNotificationTargets.InvestorOrderCreatePayload>(rowData?.targetObjectPayload)
      );
    }
    case SharedNotification.DomainDefaultNotificationName.IO_MODIFY:
    case SharedNotification.DomainDefaultNotificationName.IO_CANCEL: {
      // For IO_MODIFY and IO_CANCEL, use the targetObjectPayload (should be an InvestorOrderModifyStylePayload)
      // to determine "HasExecutedQuantity" row state.
      return IOHasExecutedQuantity(
        getParsedPayload<SharedNotificationTargets.InvestorOrderModifyStylePayload>(
          rowData?.targetObjectPayload
        )
      );
    }
    case SharedNotification.DomainDefaultNotificationName.IO_FAILED:
    case SharedNotification.DomainDefaultNotificationName.IO_MODIFY_FAILED:
    case SharedNotification.DomainDefaultNotificationName.TRADE_FAILED:
    case SharedNotification.DomainDefaultNotificationName.TRADE_MODIFY_FAILED: {
      return false;
    }
    // case 'Capital Threshold Warning':
    // case 'Unmatched Trade Report':
    // case 'Trade Report Failed (rejected)':
    // case 'TO Create Rejected':
    // case 'TO Modify Rejected':
    // case 'TO Cancel Rejected':
    // case 'TO Unsolicited Cancelled - Partial Fills':
    // case 'Trade Request (OTC)': {
    //   // TODO: For other notification types, extract the relevant object (TO, Execution, etc.)
    //   // for the "HasExecutedQuantity" row state rule.
    //   return false;
    // }
    default:
      return false;
  }
}

// Determines if the given Notification row should have the "Hazard" row state.
export function NotificationHazard(rowData: NotificationRow | undefined): boolean {
  switch (rowData?.name) {
    // Fow now only set the "Hazard" row state for Failed Orders and Trades.
    case SharedNotification.DomainDefaultNotificationName.IO_FAILED:
    case SharedNotification.DomainDefaultNotificationName.IO_MODIFY_FAILED:
    case SharedNotification.DomainDefaultNotificationName.TRADE_FAILED:
    case SharedNotification.DomainDefaultNotificationName.TRADE_MODIFY_FAILED: {
      return true;
    }
    case SharedNotification.DomainDefaultNotificationName.IO_NEW:
    case SharedNotification.DomainDefaultNotificationName.IO_MODIFY:
    case SharedNotification.DomainDefaultNotificationName.IO_CANCEL:
    // case 'Capital Threshold Warning':
    // case 'Unmatched Trade Report':
    // case 'Trade Report Failed (rejected)':
    // case 'TO Create Rejected':
    // case 'TO Modify Rejected':
    // case 'TO Cancel Rejected':
    // case 'TO Unsolicited Cancelled - Partial Fills':
    // case 'Trade Request (OTC)':
    default:
      return false;
  }
}

// Based on the notification, invoke the corresponding widget to pop up.
// For now, we only look at the notification type to invoke the widgets
// with no specifics of the individual notification.
export function invokeNotificationPopup(notification: NotificationRow, appWorkspace: AppWorkspace) {
  switch (notification.name as SharedNotification.DomainDefaultNotificationName) {
    case SharedNotification.DomainDefaultNotificationName.IO_NEW:
      openNewOrders(appWorkspace.getLeaderProcessId(), {
        componentProps: {
          autoCloseOnEmpty: true
        }
      }).catch(console.error);
      break;
    case SharedNotification.DomainDefaultNotificationName.IO_MODIFY:
    case SharedNotification.DomainDefaultNotificationName.IO_CANCEL:
      openPendingModifications(appWorkspace.getLeaderProcessId(), {
        componentProps: {
          autoCloseOnEmpty: true
        }
      }).catch(console.error);
      break;
    case SharedNotification.DomainDefaultNotificationName.IO_FAILED:
    case SharedNotification.DomainDefaultNotificationName.IO_MODIFY_FAILED:
      openRepairQueue(appWorkspace.getLeaderProcessId(), {
        componentProps: {
          tab: RepairQueueTab.INVESTOR_ORDERS,
          autoCloseOnEmpty: true
        }
      }).catch(console.error);
      break;
    case SharedNotification.DomainDefaultNotificationName.TRADE_FAILED:
    case SharedNotification.DomainDefaultNotificationName.TRADE_MODIFY_FAILED:
      openRepairQueue(appWorkspace.getLeaderProcessId(), {
        componentProps: {
          tab: RepairQueueTab.TRADES,
          autoCloseOnEmpty: true
        }
      }).catch(console.error);
      break;
  }
}
