import type { ActionDefFactory, ActionComponentConfig, ActionContext } from '@oms/frontend-vgrid';

const onChangeFn = (ctx: ActionContext<any, ActionComponentConfig<any>>) => {
  if (ctx.lifecycle === 'change') {
    ctx.api.refreshServerSide({
      purge: true
    });
  }
};

export const refreshServersideAction: ActionDefFactory<any> = (builder) =>
  builder
    .name('refresh_serverside')
    .menu((m) => m.name('Refresh Data'))
    .onChange<ActionComponentConfig<any>>(onChangeFn);

export const refreshServersideCustomMenuAction: ActionDefFactory<any> = (builder) =>
  builder
    .name('refresh_serverside')
    .onChange<ActionComponentConfig<any>>(onChangeFn)
    .customMenu((m) => m.name('Refresh Data').priority(1_100));
