import { ApolloLink, FetchResult } from '@apollo/client';
import { getMainDefinition, Observable } from '@apollo/client/utilities';
import type { OperationDefinitionNode } from 'graphql';
import * as Sentry from '@sentry/react';

const sentryGqlOpNameTag = 'graphql.operation.name';
const sentryGqlOpTypeTag = 'graphql.operation.type';
const sentryGraphqlContextName = 'Graphql Info';

/**
 * Set-up a tracing link for Apollo, to add trace context to GraphQL requests
 * @returns {ApolloLink} An ApolloLink instance to handle tracing for GraphQL requests
 */
export const createGraphQlTracingLink = (): ApolloLink => {
  return new ApolloLink((operation, forward) => {
    return Sentry.withScope((scope) => {
      const definition = getMainDefinition(operation.query);

      if (definition.kind === 'OperationDefinition' && definition.operation === 'subscription') {
        return forward(operation);
      }

      const operationType = (definition as OperationDefinitionNode).operation;

      const sentrySpanName = `graphql.${operationType}.${operation.operationName}`;
      const sentryContext = {
        [sentryGqlOpNameTag]: operation.operationName,
        [sentryGqlOpTypeTag]: operationType
      };

      scope.setContext(sentryGraphqlContextName, sentryContext);
      scope.setTags(sentryContext);

      const span = Sentry.startInactiveSpan({
        name: sentrySpanName,
        op: 'graphql',
        attributes: sentryContext,
        scope
      });

      const { spanId, traceId } = span.spanContext();

      // Set trace_id header
      operation.setContext(({ headers = {} }) => ({
        headers: {
          ...headers,
          'parent-id': traceId,
          traceparent: `00-${traceId}-${spanId}-01`,
          trace_id: traceId,
          span_id: spanId
        }
      }));

      return new Observable<FetchResult<Record<string, any>, Record<string, any>, Record<string, any>>>(
        (observer) => {
          const sub = forward(operation).subscribe({
            next: (response) => {
              span.setStatus({ code: 1 });
              observer.next(response);
            },
            error: (error: Error) => {
              scope.captureException(error);
              // special message that tells Sentry to create an issue from this span.
              span.setStatus({ code: 2, message: 'internal_error' });
              span.end();
              observer.error(error);
            },
            complete: () => {
              span.end();
              observer.complete();
            }
          });

          return () => {
            sub.unsubscribe();
            span.end();
          };
        }
      );
    });
  });
};
